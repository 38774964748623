<template>
  <div class="headPortrait">
    <!-- 可配置头像宽高 -->
    <!-- 用户有自己的头像时显示自己的头像，无时显示用户名首字母 -->
    <div
      class="logoname"
      :style="{ height: headHeight, width: headWidth }"
      v-show="!objHeadSrc && objname"
    >
      {{ objname ? objname.slice(0, 1) : "" }}
    </div>
    <img
      :src="objHeadSrc"
      alt=""
      :style="{ height: headHeight, width: headWidth }"
      v-show="objHeadSrc"
    />
  </div>
</template>

<script>
export default {
  name: "headPortrait",
  components: {},
  data() {
    return {};
  },
  props: {
    // 用户名
    objname: {
      type: String,
      default: "",
    },
    // 用户头像
    objHeadSrc: {
      type: String,
      default: "",
    },
    // 用户头像高度
    headHeight: {
      type: String,
      default: "32px",
    },
    // 用户头像宽度
    headWidth: {
      type: String,
      default: "32px",
    },
  },
  computed: {},
  watch: {},
  methods: {},
  created() {},
  mounted() {},
};
</script>
<style lang='scss' scoped>
.headPortrait {
  .logoname {
    background: #43a0ff;
    border-radius: 50%;
    color: #fff;
    text-align: center;
    line-height: 32px;
    font-size: 18px;
  }
}
</style>