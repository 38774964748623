<!-- 开发人员：马金祥 -->
<template>
    <div class="sourceManage" v-loading="loading" :element-loading-text="$t('label.report.loading')" element-loading-spinner="el-icon-loading" element-loading-background="rgba(255, 255, 255, 0.8)">
        <div id="resourceutilDiv" class="zps-resource">
            <!-- 顶部 -->
            <div class="resource-navigation-row">
                <div class="resource-navigation-row-left">
                    <div class="span1">
                        <el-dropdown @command="handleLegendClick" trigger="click" placement="bottom-start">
                            <span class="el-dropdown-link">
                                {{legend}}
                                <i class="el-icon-arrow-down el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown" class="projectDropdown">
                                <el-dropdown-item :command="$t('label.projectManagement.bar.chart')">
                                    <span>
                                        <!-- 条形图 -->
                                        <i class="el-radio-icon" :class="!isRetu?'is-checked':''"></i>{{$t("label.projectManagement.bar.chart")}}
                                    </span>
                                     <svg class="pro-icon" aria-hidden="true"> <use href="#icon-tiaoxingtu"></use> </svg>
                                </el-dropdown-item>
                                <el-dropdown-item :command="$t('label.projectManagement.heat.map')">
                                    <span>
                                        <!-- 热图 -->
                                        <i class="el-radio-icon" :class="isRetu?'is-checked':''"></i>{{$t("label.projectManagement.heat.map")}}
                                    </span>
                                     <svg class="pro-icon" aria-hidden="true"> <use href="#icon-retu"></use> </svg>
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <div class="span2">
                        <!-- <el-dropdown @command="handleDownClick" trigger="click" class="projectDropdown"> -->
                            <span class="el-dropdown-link">
                             {{$t("label.projectManagement.time.allocated")}}
                                <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
                            </span>
                            <!-- <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="已分配时间">已分配时间</el-dropdown-item>
                                <el-dropdown-item command="未分配时间" :disabled="true">未分配时间</el-dropdown-item>
                            </el-dropdown-menu> -->
                        <!-- </el-dropdown> -->
                    </div>
                </div>
                <div class="resource-navigation-row-right">
                    <div class="right-1">
                        <!-- 天 -->
                        <span @click="handleTabClick('date')" :class="checkTimeType==='date'?'is-active':''">{{$t("label.chatter.day")}}</span>
                        <!-- 周 -->
                        <span @click="handleTabClick('week')" :class="checkTimeType==='week'?'is-active':''">{{$t("label.week")}}</span>
                        <!-- 月 -->
                        <span @click="handleTabClick('month')" :class="checkTimeType==='month'?'is-active':''">{{$t("label.login.access.month")}}</span>
                    </div>
                    <div class="right-2">
                        <RangeTime :checkTimeType="checkTimeType" @getDateArry="getDateArry" />
                    </div>
                </div>
            </div>
            <!-- 视图区 动态根据用户选择天，周，月添加class-->
            <div id="resourcediv" class="resourceutilContainer" :class="[isRetu?'retu':'',checkTimeType==='date'?'days':'']">
                <div class="resourceutilTableholder" ref="resourceutilTableholder" :style="!tableList.length?'overflow: hidden':'overflow: auto'"   v-infinite-scroll="loadMore">
                    <div class="resSearchSticky">
                        <div class="rusearchholder stickyL0">
                            <div class="zps-search-box">
                                <el-input class="search-text" v-model="searchValue" :placeholder="$t('label.projectManagement.search.for.users')" @keyup.enter.native="searchList" prefix-icon="el-icon-search"></el-input>
                            </div>
                        </div>
                    </div>
                    <!-- 视图顶部固定--日期 -->
                    <div class="dateholderHeader">
                        <!-- 今天的虚线 -->
                        <div class="res-today-line" :style="{'left':todayLeft,'display':todayShow}"></div>
                        <!-- 左边成员搜索 -->
                        <div class="rusearchholder stickyL0">
                        </div>
                        <!-- 总计 -->
                        <div align="center" class="monthColumn stickyT0">
                            <div class="txtBoldBlue ellipsis">{{timeStart+"-"+timeEnd}}</div>
                            <div class="fs11px pt4">{{totalHour}}(h)</div>
                        </div>
                        <!-- 右边日期 -->
                        <template v-if="checkTimeType==='date'">
                            <template v-for="(item,index) in dateList">
                                <div align="center" class="resworkdaybg weekNameColumn" :key="index" :class="(item[1].trim()==`${$t('saturday')}`||item[1].trim()==`${$t('sunday')}`)?'weekendDay':''">
                                    <!-- <el-tooltip class="item" effect="dark" :content="`${item[0]}`" placement="top-start"> -->
                                    <div class="resworkdaycount">
                                        <p>{{item[0].substr(-2,2).indexOf("0")==0?item[0].substr(-2,2).split("0")[1]:item[0].substr(-2,2)}}</p>
                                        <p class="blueColor">{{item[1]}}</p>
                                    </div>
                                    <!-- </el-tooltip> -->
                                </div>
                            </template>
                        </template>
                        <template v-if="checkTimeType!=='date'">
                            <template v-for="(item,index) in dateList">
                                <div align="center" class="resworkdaybg weekNameColumn" :key="index">
                                    <!-- <div class="resworkdaycount" v-show="checkTimeType==='week'">{{(item[0].substr(5,2).indexOf("0")==0?item[0].substr(5,2).split("0")[1]:item[0].substr(5,2))+"月"+item[0].substr(-2,2).indexOf("0")==0?item[0].substr(-2,2).split("0")[1]:item[0].substr(-2,2)}}</div>
                                    <div class="resworkdaycount" v-show="checkTimeType==='month'">{{item.toString().substr(5,2).indexOf("0")==0?item.toString().substr(5,2).split("0")[1]:item.toString().substr(5,2)+"月"+"-"+item.toString().substr(0,4)}}</div> -->
                                      <div class="resworkdaycount" v-show="checkTimeType==='week'">{{(item[0].substr(5,2).indexOf("0")==0?item[0].substr(5,2).split("0")[1]:item[0].substr(5,2))+"/"+(item[0].substr(-2,2).indexOf("0")==0?item[0].substr(-2,2).split("0")[1]:item[0].substr(-2,2))+"-"+(item[1].substr(5,2).indexOf("0")==0?item[1].substr(5,2).split("0")[1]:item[1].substr(5,2))+"/"+(item[1].substr(-2,2).indexOf("0")==0?item[1].substr(-2,2).split("0")[1]:item[1].substr(-2,2))}}</div>
                                    <div class="resworkdaycount" v-show="checkTimeType==='month'">{{item}}</div>
                                    
                                </div>
                            </template>
                        </template>
                    </div>
                    <!-- 视图底部展示 -->
                    <div class="resource-list-content" :style="{width:resourceContentW}">
                        <!-- 每一条任务 -->
                        <template v-for="(list,index) in tableList">
                            <div class="resource-list-parent" :key="list.memberId">
                                <!-- 任务展示 -->
                                <div class="ruflexRowContainer ruflexRowContainerTop">
                                    <!-- 左侧成员名称 -->
                                    <div class="ruUsernameHolder stickyL0">
                                        <div class="ruContainer">
                                            <!-- <img height="32" width="32"
                                                :src="`${$cookies.get('domainName')}/chatterzone.action?m=showPhoto&userid=${list.userId}&binding=${$cookies.get('binding')}&wj=${Math.random()}`" /> -->
                                                 <headPortrait :objname="list.memberName" />
                                            <div class="flex fdcolumn ml10 minw0">
                                                <div class>
                                                   <el-tooltip class="item" effect="dark" :content="list.memberName" placement="top-start">
                                                         <span class="res-username">{{list.memberName}}</span>
                                                   </el-tooltip>
                                                </div>
                                                <div class="res-taskcount" @click="showCurRow(index,list)">
                                                    <span>{{list.tasknum}}{{$t("label.multipleactivity.atasks")}}</span>
                                                    <i :class="list.show?'el-icon-arrow-up':'el-icon-arrow-down'" v-show="list.tasknum>0"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- 总计 -->
                                    <el-tooltip :open-delay="400"  placement="top" popper-class="sorcePoper">
                                        <div slot="content">
                                            <!-- 划过 -->
                                            <div class="hoverBox">
                                                <p>{{$t("label.projectManagement.assigned.work")}}：{{list.memberAllTime}}(h)</p>
                                                <p>{{totalHour-list.memberAllTime>0?`${$t('label.projectManagement.spare.time')}:${totalHour-list.memberAllTime}(h)`:`${$t('label.projectManagement.distribution.by.excess')}:${list.memberAllTime-totalHour}(h)`}}</p>
                                            </div>
                                        </div>
                                        <div class="monthnameColumn resbdrRight mh55" align="center">
                                            <div class="flex aicenter">
                                                <div class="res-rangecol-bar">
                                                    <span class="underallocated" :class="((list.memberAllTime/totalHour)*100>100||(list.memberAllTime/totalHour)*100==100)? 'derallocated':''"
                                                        :style="{'width':(list.memberAllTime/totalHour)*100+'%'}"></span>
                                                </div>
                                            </div>
                                            <span class="resdetail-span bugoflowh">{{list.memberAllTime}}</span>
                                        </div>
                                    </el-tooltip>
                                    <!-- 日期1 -->
                                    <template v-for="(item,timeIndex) in list.data">
                                        <el-tooltip placement="top" :key="timeIndex" popper-class="sorcePoper">
                                            <div slot="content">
                                                <!-- 划过 -->
                                                <div class="hoverBox">
                                                    <p>{{$t("label.projectManagement.assigned.work")}}：{{item.allocateTime}}(h)</p>
                                                    <p>{{item.overTime>0?`${$t('label.projectManagement.distribution.by.excess')}：${item.overTime}(h)`:`${$t('label.projectManagement.spare.time')}：${item.spareTime}(h)`}}</p>
                                                </div>
                                            </div>
                                            <div class="ruDaycolumn resbdrRight mh55" align="center" >
                                                <!--1、分配时间=0全灰不添加calss;2、分配时间>每周的工作时间全红添加red;3、分配时间=每周的工作时间全绿添加green;4、分配时间<每周的工作时间半绿半浅绿添加green并设置宽高-->
                                                <div class="res-alloc-bar" v-show="item.allocateTime===0">
                                                    <!-- 全灰（分配0)-->
                                                    <span></span>
                                                </div>
                                                <div class="res-alloc-bar red" v-show="item.overTime>0">
                                                    <!-- 会出现全红（分配超过100% -->
                                                    <span :style="{'width': (isRetu?'100%':'100%'),'height': (!isRetu? '100%':'100%')}"></span>
                                                </div>
                                                <div class="res-alloc-bar green" v-show="item.allocateTime>0&&item.reasonableTime===item.allocateTime">
                                                    <!-- 全绿（分配时间大于0且分配时间=工作时间） -->
                                                    <span :style="{'width': (isRetu?'100%':'100%'),'height': (!isRetu? '100%':'100%')}"></span>
                                                </div>
                                                <div class="res-alloc-bar green" v-show="item.allocateTime>0&&item.allocateTime<item.reasonableTime&&item.overTime===0">
                                                    <!-- 半绿半浅绿（分配时间小于工作时间且大于0，且超过分配时间为0） -->
                                                    <span
                                                        :style="{'width': (isRetu?'100%':parseInt(item.allocateTime/item.reasonableTime*100)+'%'),'height': (!isRetu? '100%':parseInt(item.allocateTime/item.reasonableTime*100)+'%')}"></span>
                                                </div>
                                                <!-- 分配时间 -->
                                                <span class="resdetail-span bugoflowh">{{item.allocateTime}}</span>
                                                <!-- 加号 -->
                                                <span class="el-icon-circle-plus-outline ruplusicon" @click="addTask(timeIndex,list.memberId,list.memberName)"></span>
                                            </div>
                                        </el-tooltip>
                                    </template>
                                </div>
                                <!-- 成员展开 -->
                                <div class="resource-list-child" v-show="list.show" v-loading="list.loading" :element-loading-text="$t('label.report.loading')" element-loading-spinner="el-icon-loading"
                                    element-loading-background="rgba(0, 0, 0, 0.8)">
                                    <div class="ruflexRowContainer droppableOne" v-for="(dragitem,dragIndex) in list.children" :key="dragitem.taskId">
                                        <!-- 任务名称 -->
                                        <div class="stickyL0 resNmParent" @click="setScroll(index,dragIndex)">
                                            <el-tooltip class="item" effect="dark" :content="$t('label.projectManagement.view.details')" placement="top-start">
                                                <i class="el-icon-s-operation" style="padding:0 20px;" @click="handleTask(dragitem.taskId)" ></i>
                                            </el-tooltip>
                                            <el-tooltip placement="right" :open-delay="400" popper-class="sorcePoper">
                                                <div slot="content">
                                                    <!-- 划过任务浮窗 -->
                                                    <div class="hoverTaskBox">
                                                        <p class="hoverTaskBoxTitle">{{dragitem.taskName}}</p>
                                                        <p>
                                                            <!-- 日期 -->
                                                            {{$t("date")}}:
                                                            <span>{{dragitem.startDate+"-"+dragitem.endDate}}</span>
                                                        </p>
                                                        <p>
                                                           {{$t("label.appointmentdetail.wizard.title3")}}:
                                                                <!-- 持续时间 -->
                                                            <span>{{dragitem.continueDate}}</span>
                                                        </p>
                                                        <p>
                                                           {{$t("label.projectManagement.working.hours")}}:
                                                                <!-- 工作时间 -->
                                                            <span>{{dragitem.workHour*dragitem.continueDate}}(h)</span>
                                                            <!-- 工作时间为：持续时长*每天工作小时数 -->
                                                        </p>
                                                    </div>
                                                </div>
                                                <div class="taskTxt">
                                                    {{dragitem.taskName}}
                                                </div>
                                             </el-tooltip>
                                        </div>
                                        <!-- 总计 -->
                                        <div class="monthnameColumn"></div>
                                        <!-- 可拖拽任务 -->
                                        <div class="ruDaycolumntask">
                                            <div class="dragOne">
                                                <!-- v-if="dragContainerWidth!=='100%'" 在宽度赋予固定值后再创建拖拽元素，天周月切换后重新绘制任务拖拽条-->
                                                <template>
                                                    <div class="dragItem" :style="{'width':dragContainerWidth}">
                                                        <VueDraggableResizable :grid="[dragitem.dragx,50]" :w="dragitem.w" :min-width="dragitem.minW" :h="8" :x="dragitem.x" :y="25"
                                                            :handles="['ml','mr']" axis="x" @resizestop="resize" :parent="true" @dragstop="drag" 
                                                            :ref="`drag${index}-${dragIndex}`"
                                                            @activated="activateEv(dragIndex,index,dragitem.principal,dragitem.startDate,dragitem.endDate)"
                                                            v-if="dragContainerWidth!=='100%'&&getParentWSuccess">
                                                            <i></i>
                                                            <!-- 任务名称 -->
                                                            <el-tooltip placement="top" :open-delay="100" popper-class="sorcePoper">
                                                                <div slot="content">
                                                                    <div class="hoverTaskBox">
                                                                        <p class="hoverTaskBoxTitle">{{dragitem.taskName}}</p>
                                                                        <p>
                                                                          {{$t("date")}}:
                                                                          <!-- 日期 -->
                                                                            <span>{{dragitem.startDate+"-"+dragitem.endDate}}</span>
                                                                        </p>
                                                                        <p>
                                                                           {{$t("label.appointmentdetail.wizard.title3")}}:
                                                                         <!-- 持续时间 -->
                                                                            <span>{{dragitem.continueDate}}(day)</span>
                                                                        </p>
                                                                        <p>
                                                                           {{$t("label.projectManagement.working.hours")}}:
                                                                                    <!-- 工作时间 -->
                                                                            <span>{{dragitem.workHour*dragitem.continueDate}}(h)</span>
                                                                            <!-- 工作时间为：持续时长*每天工作小时数 -->
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <el-popover
                                                                    placement="bottom"
                                                                    width="360"
                                                                    trigger="click" 
                                                                    :ref="`taskPop${index}-${dragIndex}`"
                                                                    @hide="handleCancle(index,dragIndex)"
                                                                    popper-class="taskPop">
                                                                    <div class="pTanchuang">
                                                                        <div class="flexTask">
                                                                            <!-- 负责人 -->
                                                                            <span>{{$t("label_person_in_charge")}}</span>
                                                                            <!--  每天工作小时数-->
                                                                            <span>{{$t("label.projectManagement.working.hours.per.day")}}</span>
                                                                        </div>
                                                                        <div class="flexTask">   
                                                                            <p>{{list.memberName}}</p>
                                                                            <div>
                                                                                <el-input
                                                                                    :key="'input' + index"
                                                                                    v-model="dragitem.changeHour"
                                                                                    @keyup.native="proving($event)"
                                                                                    @keyup.enter.native="handleSure(index,dragIndex,dragitem.changeHour,dragitem.taskId,list.memberId,dragitem.objectApi)"
                                                                                >
                                                                                </el-input> 
                                                                                <span class="hour">h</span>
                                                                            </div>
                                                                        </div>   
                                                                    </div>
                                                                    <div style="text-align: right; margin: 0"> 
                                                                        <!-- 取消 -->
                                                                        <el-button  size="mini" type="text" @click="handleCancle(index,dragIndex)">{{$t("label.emailsync.button.cancel")}}</el-button>
                                                                        <!-- 确定 -->
                                                                        <el-button type="primary" size="mini" @click="handleSure(index,dragIndex,dragitem.changeHour,dragitem.taskId,list.memberId,dragitem.objectApi)">{{$t("label.tabpage.ok")}}</el-button>
                                                                    </div>
                                                                    <div class="dragItemInner" slot="reference"></div>
                                                                </el-popover>
                                                            </el-tooltip>
                                                            <i></i>
                                                        </VueDraggableResizable>
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="ruflexRowContainer" v-if='!tasksmore[list.memberId]'><div class="stickyL0 resNmParent loadingmore" @click="moreadd(index,list)">{{$t('label.chatter.loadmore')}} <i class="el-icon-arrow-down"></i></div></div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <!-- 占位 -->
                    <div style="flex:1" class="stickySpecial" :style="{height:stickySpecialHeight}">
                    </div>
                       <!-- 暂无数据 -->
                    <!-- <div class="noData" v-if="!tableList.length">
                        <temporarily-no-data />
                    </div> -->
                </div>
            </div>
        </div>
        <!-- 新增弹窗类型选择 别删 别删 -->
        <!-- <el-dialog
        title="选择"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
        <span style="margin-left: 80px; margin-right: 5px">选择新建类型</span>
            <el-select v-model="addType" placeholder="请选择">
                <el-option
                v-for="item in typeMap" 
                :key="item.value"
                :label="item.label"
                :value="item.value">
                </el-option>
            </el-select>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="handelNext">下一步</el-button>
            </span>
        </el-dialog> -->
        <!-- 新增/修改记录 -->
        <create-edit-obj
        ref="createEditObj"
        :prefix="prefix"
        :objectApi="objectApi"
        :object-name="objectName"
        :realObjId="realObjId"
        :type="'active'"
        :relationFieldId="relationFieldId"
        :relativeRecordId="relativeRecordId"
        @save="save"
        >
        </create-edit-obj>
    </div>
</template>

<script>
import RangeTime from "./components/RangeTime";
import VueDraggableResizable from "vue-draggable-resizable"; //https://www.npmjs.com/package/vue-draggable-resizable //https://www.cnblogs.com/wangweizhang/p/11241788.html
import "vue-draggable-resizable/dist/VueDraggableResizable.css";
import { cloneDeep } from "lodash";
import createEditObj from '@/views/commonObjects/components/create-edit-obj.vue'
import * as projectApi from "../api";//api
import headPortrait from "./components/headPortrait";
import debounce from "lodash.debounce";

export default {
    name: "test",
    components: {
        RangeTime,
        VueDraggableResizable,
         createEditObj,
         headPortrait,
    },
    props: {
        // 项目id
        projectId: {
            type: String,
            default: ""
        },
        // 项目名称
        selectname: {
            type: String,
            default: ""
        },
    },
    data() {
        return {
            isRetu: true, //是否为热图
            list: [],
            childKey: null, //子元素key
            parentIndex: null, //父元素index
            curColum: null, //子元素当前行
            newMap: [],
            checkTimeType: "date",
            dateList: [], //日期数组
            distribution: this.$i18n.t('label.projectManagement.time.allocated'), //已分配到的时间。。分配下拉
            legend:this.$i18n.t('label.projectManagement.heat.map'),//热图
            legendType: 1,
            curStart: "", //当前任务的开始时间
            curEnd: "", //当前任务的结束时间
            loading: false,
            todayLeft: "0px", //今天线距离左边的位置
            curWidth: "", //日期划分每一格的宽度
            todayShow: "none", //今天时间线的显隐
            dragContainerWidth: "100%", //拖拽元素父级的宽度
            tableList: [], //成员数据行
            getParentWSuccess: false, //控制拖拽条的父元素宽度是否更新完成
            resourceContentW: null, //除顶部日期外底部的视图宽度
            totalHour: 0, //总工作时长
            timeStart: "", //页面总计显示-开始时间
            timeEnd: "", //页面总计显示-结束时间
            startDate: "", //接口传参-开始时间
            endDate: "",//接口传参-结束时间
            searchValue: "",
            taskPop:false,
            dialogVisible:false,
            addType:1, //默认新建任务
            typeMap:[{
                value: 1,
              label: this.$i18n.t('label.projectManagement.new.tasks')
                //新增任务
                },{
                value: 2,
                label: this.$i18n.t('label.projectManagement.new.service.appointment')
                //新增服务预约
            }],
            prefix:"",
            objectApi:"",
            objectName:"",
            realObjId:"",
            relationFieldId:"",
            relativeRecordId:"",
            stickySpecialHeight:0,
            addIndexMemberId:'',//增加任务时属于哪个成员的，记录成员id
            relationResource:{
                curMemberId:"",
                curMemberName:"",
                curDate:"",
                proId:"",
                proName:"",
            },
            pagesize:10,//每页数据条数
            page:1,//页数
            nextpage:'true',//是否有下一页
            userpage:{},//人员下页数
            tasksize:50,//每个人下每页任务的数量
            tasksmore:{},//每个人下是否还有下一页
        };
    },
    computed: {},
    watch: {
        projectId: {  // 监听项目id发生变化重新调取接口
            handler(val) {
                if (val) {
                   this.getRightView();
                }
            },
        },
        searchValue: {  // 监听成员名称发生变化重新调取接口
            handler(val) {
                if (val == "") {
                    this.getRightView();
                }
            },
        }
    },

    methods: {
        moreadd(index,row){
            this.userpage[row.id]++;
            this.tableList[index].loading = true;
            this.tableList[index].show = !this.tableList[index].show;
            // 调取接口，获取成员的任务明细
            let params = {
                projectId:this.$route.query.proId?this.$route.query.proId:this.projectId,
                startDate: this.startDate,
                endDate: this.endDate,
                memberId: row.memberId,
                taskPagination:this.userpage[row.id].toString(),
                taskPageSize:this.tasksize.toString(),
                // dataState:''
            };
            if (row.tasknum > 0 && row.show) { //任务数大于0点击时调取接口
                this.getTaskDetail(index, params,'add');
            }
        },
         // 滚动事件
        loadMore() {
            let that = this;
            // let dom = document.querySelector('.el-table__body-wrapper')
            // let dom = document.getElementsByClassName("resourceutilContainer")[
            //     document.getElementsByClassName("resourceutilContainer").length - 1
            // ];
            // dom.addEventListener("scroll", function () {
            //     const scrollDistance =
            //     dom.scrollHeight - dom.scrollTop - dom.clientHeight;
            //     if (scrollDistance <= 10) {
                //等于0证明已经到底，可以请求接口
                if(that.nextpage=='false'){
                    that.getRightView('isadd')
                }
            //     }
            // });
        },
        proving(e) {
            // 正数，最多一位小数的正数
            // const reg = /^(([1-9]{1}\d*)|([0]{1}))(\.(\d){0,1})?$/;
            const reg = /^(([1-9]{1}\d*))?$/;
            if (!reg.test(e.target.value)) {
                // this.$message.warning("请输入最多一位小数的数字");
                this.$message.warning(this.$i18n.t('label.projectManagement.please.enter.a.positive.integer'));//  //请输入正整数
                this.$set(this.tableList[this.parentIndex].children[this.curColum],"changeHour", ""); //输入错误清空
            }
            if (e.target.value&&e.target.value>24) {
                this.$message.warning(this.$i18n.t('label.projectManagement.must.be.less.than.24.hours'));//必须小于24小时
                this.$set(this.tableList[this.parentIndex].children[this.curColum],"changeHour", ""); //输入错误清空
            }
        },
        // 修改时间取消或者点击空白处关闭
        handleCancle(index,dragIndex){
            let _pop = 'taskPop'+index+"-"+dragIndex;
            this.$refs[_pop][0].doClose();
            // 关闭浮窗后实际恢复默认
            this.tableList[this.parentIndex].children[this.curColum].changeHour = this.tableList[this.parentIndex].children[this.curColum].workHour;
        },
        // 修改时间确定
        handleSure(index,dragIndex,hour,taskId,memberId,objectapi){
            let _pop = 'taskPop'+index+"-"+dragIndex;
            this.$refs[_pop][0].doClose();
            this.addIndexMemberId = memberId;
            //服务预约
            if (objectapi=="ServiceAppointment") {
               this.$message.warning(this.$i18n.t('label.projectManagement.service.appointment.work.noedit'));
                //服务预约工时不可编辑
                return
            }
            //获取任务或子任务下的工时信息
            projectApi.getTaskHour({taskId:taskId,memberId:memberId}).then((res) => {
               let _id= res.data[0].id
                //任务
                if (objectapi=="cloudccTask") {
                    // 调取接口更新工作时间
                    let data = [{
                        their_task: taskId,
                        daily_working_hours:hour,
                        principal:memberId,
                        their_subtask:'',
                        id: _id
                    }]
                    let params = {
                        data: JSON.stringify(data),
                        objectApi:"cloudccTaskHour",
                        marketsea: false
                    }
                    this.dragSave(params);
                    return
                }
                //子任务
                if (objectapi=="cloudccSubtask") {
                    // 调取接口更新工作时间
                    let data = [{
                        their_subtask:taskId,
                        their_task:"",
                        daily_working_hours:hour,
                        principal:memberId,
                        id: _id
                    }]
                    let params = {
                        data: JSON.stringify(data),
                        objectApi:"cloudccTaskHour",
                        marketsea: false
                    }
                     this.dragSave(params);
                    return
                }
           
            
            }).catch(() => {
    
            })
           
           
        },
        // 成员检索
        searchList() {
            this.getRightView();
        },
        // 改变大小
        resize(left, top, width) {
            // 拖动右改变大小需更新持续时长以及开始结束日期
            let curDragWidth = 0;
            if (this.checkTimeType === "date") {
                curDragWidth = parseInt(width / this.curWidth);
            } else if (this.checkTimeType === "week") {
                curDragWidth = Math.round(width / (this.curWidth/7));
            } else if (this.checkTimeType === "month") {
               let leftMonthDays = this.$moment(this.tableList[this.parentIndex].children[this.curColum].endDate, "YYYY-MM-DD").daysInMonth(); //结束日期所在月有多少天
               curDragWidth = Math.round(width / (this.curWidth/leftMonthDays));
            }
            curDragWidth =curDragWidth<1?1:curDragWidth;
            this.addIndexMemberId =this.tableList[this.parentIndex].memberId
            this.getDragItem(left, curDragWidth,2);
        },
        // 拖拽结束事件
        drag(left) {
            // 左右拖拽只需更新开始结束日期，可取返回持续时间作为依据,
            // 1、开始时间停在周六，周六算做工作时间，周天不算
            // 2、开始时间停在周天，周天算工作时间
            this.addIndexMemberId = this.tableList[this.parentIndex].memberId
            let continueDate = this.tableList[this.parentIndex].children[this.curColum].continueDate * 1; //当前拖拽元素宽度占几格
            this.getDragItem(left, continueDate,1);
        },
        addWeekdays(date, days) {
            date = this.$moment(date); 
            while (days > 1) {
                date = date.add(1, 'days');
                if (date.isoWeekday() !== 6 && date.isoWeekday() !== 7) {
                    days -= 1;
                }
            }
            return date;
        },
        getRestDays(bd,ed){
            let d1=new Date(bd),d2=new Date(ed);
            let dateSpan=d2-d1;
            let days=parseInt(dateSpan/(24*3600*1000))+1;//计算两个日期间的天数差，加1是为了把结束日期计算在内
            let weeks=parseInt(days/7,10);
            let result=weeks*2;
            if(days%7>0)
            {
                let leftdays=days%7;
                let week1=d1.getDay(); //周日=0，周一=1，依次。。       
                if(week1==0)//如果第一个日期从周日开始，剩余天数不足一周（7天）
                {
                    result +=1;
                }
                else if(week1+leftdays>7)//如果第一个日期从周一到周六，加上剩余天数大于7，表示包含周六和周日，所以有两天
                {
                    result +=2;
                }
                else if(week1+leftdays==7)//如果刚好到周六，有一天休息日
                {
                    result +=1;
                }
            }
            return result;
        },
        getDragItem(left, continueDate,type) {
            // continueDate 任务占几格,数字
            let dragWidth = 0;
            let dragXitem = parseInt(left / this.curWidth); //拖拽在第几格，计算开始结束日期，为dateList里的第几个
            let curStartDate = '', curEndDate = '';
            // let parentWidth = this.resourceContentW.split("px")[0] * 1 - 300;
            if (this.checkTimeType === "date") {
                dragWidth = this.curWidth;
                curStartDate = this.dateList[dragXitem][0];
            } else if (this.checkTimeType === "week") {
                dragWidth = this.curWidth / 7;
                let dragAddDays = Math.round((left - dragXitem*this.curWidth)/dragWidth);
                curStartDate = this.$moment(this.dateList[dragXitem][0]).add(dragAddDays, 'days').format('YYYY-MM-DD');
            } else if (this.checkTimeType === "month") {
                // 这里还需优化，考虑向左向右整体移动
                let leftMonthDays = "";
                if(type===1){
                    leftMonthDays = this.$moment(this.dateList[dragXitem], "YYYY-MM-DD").daysInMonth();
                }else{
                    leftMonthDays = this.$moment(this.tableList[this.parentIndex].children[this.curColum].endDate, "YYYY-MM-DD").daysInMonth(); //结束日期所在月有多少天
                }
                dragWidth = this.curWidth / leftMonthDays;
                let dragAddDays = Math.round((left - dragXitem) / dragWidth) - dragXitem * leftMonthDays;
                curStartDate = this.$moment(this.dateList[dragXitem]).add(dragAddDays, 'days').format('YYYY-MM-DD');
            }
          if(type===1){
                // 整体水平拖动需跨过周末
                curEndDate = this.addWeekdays(this.$moment(curStartDate), continueDate).format('YYYY-MM-DD');
                let _diff = this.$moment(curEndDate).diff(this.$moment(curStartDate), "days");
                // 遇到周末改变宽度
                this.tableList[this.parentIndex].children[this.curColum].w =(_diff+1) * dragWidth;
            }
            if(type===2){
                // 左右拖拽改变宽度，可停留在周末，周末日期不计入持续时长
                curEndDate = this.$moment(curStartDate).add(continueDate-1, 'days').format('YYYY-MM-DD');
                // let _curStartDate = this.$moment(curStartDate).add(1, 'days').format('YYYY-MM-DD'); //如果开始日期在周六或者周天则向后推一天，开始日期所在日计算为持续时长
                // continueDate = continueDate - this.getRestDays(_curStartDate,curEndDate);//改变持续时长，减去周末
            }
            // 更新开始结束时间和持续时长
            this.tableList[this.parentIndex].children[this.curColum].startDate = curStartDate;
            this.tableList[this.parentIndex].children[this.curColum].endDate = curEndDate;
            // this.tableList[this.parentIndex].children[this.curColum].continueDate = continueDate;
            // 距离左边距离几像素问题处理
            // let curTotalWidth = continueDate*dragWidth;//当前任务条的总宽度
            // let _left = parentWidth-curTotalWidth- left;
            // let isSpecial = _left - left<=dragWidth||parseInt(_left)==parseInt(dragWidth)
            // // 解决拖拽到最右边时几像素问题导致不能靠边
            // if(isSpecial){//父元素宽度减去条的总宽度=距离左边的距离
            //     this.tableList[this.parentIndex].children[this.curColum].x = parentWidth-curTotalWidth;
            // }
            // 如果是服务预约 
          if(this.tableList[this.parentIndex].children[this.curColum].objectApi=="ServiceAppointment"){
            let data = [{
                id: this.tableList[this.parentIndex].children[this.curColum].taskId,
                actualstarttime: this.tableList[this.parentIndex].children[this.curColum].startDate,
                actualendtime: this.tableList[this.parentIndex].children[this.curColum].endDate
            }]
            let params = {
                data: JSON.stringify(data),
                objectApi:this.tableList[this.parentIndex].children[this.curColum].objectApi,
                marketsea: false
            }
             this.dragSave(params);
          }else{
              let data = [{
                id: this.tableList[this.parentIndex].children[this.curColum].taskId,
                start_date: this.tableList[this.parentIndex].children[this.curColum].startDate,
                end_date: this.tableList[this.parentIndex].children[this.curColum].endDate
            }]
            let params = {
                data: JSON.stringify(data),
                objectApi:this.tableList[this.parentIndex].children[this.curColum].objectApi,
                marketsea: false
            }
             this.dragSave(params);
          }
        },
        // 活跃元素
        activateEv(colum, pindex, cindex, start, end) {
            this.newMap = cloneDeep(this.tableList); //操作任务前拷贝一份数据，在保存接口失败时将视图恢复到前一次
            // cindex 这个取值为接口中每条任务的id，必须唯一，否则会造成拖动过程中相同的其他元素会跟着动
            this.curColum = colum;
            this.parentIndex = pindex;
            this.childKey = cindex;
            this.curStart = start;
            this.curEnd = end;
        },
        // 获取成员资源利用情况
        getMemberSource(apiType, params) {
            return new Promise(function (resolve, reject) {
                projectApi[apiType](params).then((res) => {
                    resolve(res)
                }).catch((err) => {
                    reject(err);
                })
            });

        },
        // 获取任务数
        getMemberAndTask(params) {
            return new Promise(function (resolve, reject) {
                projectApi.getMemberAndTask(params).then((res) => {
                    resolve(res)
                }).catch((err) => {
                    reject(err);
                })
            })
        },
        // 右侧大视图
        getRightViewData(apitype, params1, params2,isDrag) {
            Promise.all([this.getMemberAndTask(params1), this.getMemberSource(apitype, params2)]).then((res) => {
                this.totalHour = res[1].data.workingHours;
                this.nextpage = res[0].data.isLast;
                if(isDrag==='isDrag'){
                    // 更新任务条相关信息时
                    if (res[0].data.list.length > 0 && res[1].data.data.length > 0) {
                        let arr1 = res[0].data.list;
                        let arr2 = res[1].data.data;
                        for (let i = 0; i < arr1.length; i++) {
                            for (let j = 0; i < arr2.length; j++) {
                                if (arr1[i].id === arr2[j].memberId) {
                                    let taskparams = {
                                        projectId:this.$route.query.proId?this.$route.query.proId:this.projectId,
                                        startDate: this.startDate,
                                        endDate: this.endDate,
                                        memberId: arr1[i].id,
                                        // dataState:''
                                    };
                                    this.tableList[i].memberAllTime=arr2[j].memberAllTime
                                    this.tableList[i].data = arr2[j].data
                                    this.tableList[i].tasknum=arr1[i].tasknum 
                                    this.tableList[i].userId=arr1[i].userId 
                                    this.tableList[i].memberId=arr2[j].memberId
                                    this.tableList[i].memberName=arr2[j].memberName
                                    this.getTaskDetail(i,taskparams);//更新当前展开成员的任务情况，其他成员的在展开时会重新调取接口
                                    break
                                 }
                            }
                        }
                    }
                }else{
                    // 初次加载
                    if(isDrag!=='isadd'){
                        this.tableList = [];
                    } 
                    try {
                        if (res[0].data.list.length > 0 && res[1].data.data.length > 0) {
                            let arr1 = res[0].data.list;
                            let arr2 = res[1].data.data;
                            for (let i = 0; i < arr1.length; i++) {
                                for (let j = 0; i < arr2.length; j++) {
                                    if (arr1[i].id === arr2[j].memberId) {
                                        this.tableList.push({
                                            show: false,
                                            loading: false,
                                            memberAllTime: arr2[j].memberAllTime,
                                            userId: arr1[i].userId,
                                            memberId: arr2[j].memberId,
                                            memberName: arr2[j].memberName,
                                            data: arr2[j].data,
                                            tasknum: arr1[i].tasknum,
                                            children: [], //任务明细数组初始化
                                        })
                                        break
                                    }
                                }
                            }
                        }
                    } catch (error) {
                        // 
                    }
                }
                this.$nextTick(()=>{
                    // 控制左边占位元素的高度，解决任务成员少时滚动条滑动，左边未被元素覆盖问题
                    let _listContentHeight = document.querySelectorAll('.resource-list-content')[0].offsetHeight;
                    let _resourceutilTableholderHeight = document.querySelectorAll('.resourceutilTableholder')[0].offsetHeight;
                    this.stickySpecialHeight = _resourceutilTableholderHeight - _listContentHeight +"px";
                })
                // this.loading = false;
            }).catch(() => {
                // 点周和月请求失败了回到默认天的视图
                this.checkTimeType = "date";
                // this.loading = false;
            })
        },
        getRightView: debounce(function (isDrag) {
            // this.loading = true;
            if(isDrag){
                this.page++;
            }
            let params1 = {
                projectId:this.$route.query.proId?this.$route.query.proId:this.projectId,
                startDate: this.startDate,
                endDate: this.endDate,
                memberName: this.searchValue,
                cloudccPageSize:this.pagesize.toString(),
                cloudccPagination:this.page.toString(),
            };
            let params2 = {
                projectId:this.$route.query.proId?this.$route.query.proId:this.projectId,
                startDate: this.startDate,
                endDate: this.endDate,
                cloudccPageSize:this.pagesize.toString(),
                cloudccPagination:this.page.toString(),
            };
            // 获取每个成员的任务数
            // 根据天周月切换调取不同接口
            if (this.checkTimeType === "date") {
                // 天
                this.getRightViewData("getMemberTotalHour", params1, params2,isDrag);
            } else if (this.checkTimeType === "week") {
                // 周
                this.getRightViewData("getMemberTotalHourWeek", params1, params2,isDrag);
            } else if (this.checkTimeType === "month") {
                // 月
                this.getRightViewData("getMemberTotalHourMonth", params1, params2,isDrag);
            }
        },200),
        getDateArry(dateList, timeList) {
            this.getParentWSuccess = false;
            this.dateList = dateList;
            this.timeStart = this.$moment(timeList[0]).format("M/D");
            this.timeEnd = this.$moment(timeList[1]).format("M/D");
            this.startDate = this.$moment(timeList[0]).format("YYYY-MM-DD");
            this.endDate = this.$moment(timeList[1]).format("YYYY-MM-DD");
            this.getRightView();
            this.$nextTick(() => {
                this.resourceContentW = document.getElementsByClassName("dateholderHeader")[0].offsetWidth + "px";
                this.dragContainerWidth = document.getElementsByClassName("dateholderHeader")[0].offsetWidth - 300 + "px";
                this.getParentWSuccess = true;
                //给拖拽元素的父级赋值固定宽度，解决在有滚动条时父级设置宽度为百分比，滚动条后的拖拽不动
                this.curWidth = document.getElementsByClassName("weekNameColumn")[0].offsetWidth;
                let _diff = this.$moment(this.$moment()).diff(timeList[0], "days"); //获取时间选择器的结束日期和今天之间的时间差,计算今天时间left的值
                let _diff1 = this.$moment(timeList[1]).diff(this.$moment(), "days");
                // 今天的时间线展示,今天时间在开始结束日期中间才显示今天的时间线
                if (_diff1 > 0) {
                    if (this.checkTimeType === "date") {
                        this.todayLeft = this.curWidth * _diff + 328 + "px";
                    } else if (this.checkTimeType === "week") {
                        this.todayLeft = _diff * (this.curWidth / 7) + 300 + this.curWidth / 7 / 2 + "px";
                    } else {
                        let _monthDays = this.$moment(timeList[0], "YYYY-MM").daysInMonth(); //获取当前月有多少天
                        this.todayLeft = _diff * (this.curWidth / _monthDays) + 300 + this.curWidth / _monthDays / 2 + "px";
                    }
                    this.todayShow = "block";
                } else {
                    this.todayShow = "none";
                }
            });
        },
        // 点击天周月切换
        handleTabClick(val) {
            this.checkTimeType = val;
            this.getParentWSuccess = false;
        },
        handleDownClick(val) {
            this.distribution = val;
        },
        handleLegendClick(val) {
            this.legend = val;
            if (val === this.$i18n.t('label.projectManagement.heat.map')) {
                this.isRetu = true;
            } else {
                this.isRetu = false;
            }
        },
        //添加任务
        addTask(index,memberId,memberName) {
           // 
            this.relativeRecordId = this.projectId;
            this.addIndexMemberId = '';//添加任务时可能操作多个成员，因此循环更新所有成员
            //this.dialogVisible = true;
            // 点击任务时存储当前成员id以及当前时间，新增弹窗回显使用
            this.relationResource.curMemberId=memberId;
            this.relationResource.curMemberName=memberName;
            this.relationResource.proId= this.projectId;
            this.relationResource.proName= this.selectname
            if(this.checkTimeType==='month'){
                this.relationResource.curDate=this.dateList[index];
            }else{
                this.relationResource.curDate=this.dateList[index][0];
            }
            this.$store.commit('updateRelationResource', this.relationResource)
            // 
            this.prefix='p03';
            this.objectApi='cloudccTask';
            this.objectName=this.$i18n.t('label.projectManagement.project.task'); //项目任务
            this.realObjId='cloudccTask';
            this.relationFieldId = "ffe20201207task03";
            this.$nextTick(()=>{
                this.$refs.createEditObj.add();
            })
        },
        // 关闭任务类型选择
        handleClose(){
            this.dialogVisible = false;
        },
        // 点击添加任务-类型选择框下一步
        // handelNext(){
        //     if(this.addType===1){
        //         // 任务
        //         this.prefix='p03';
        //         this.objectApi='cloudccTask';
        //         this.objectName='项目任务';
        //         this.realObjId='cloudccTask';
        //         this.relationFieldId = "ffe20201207task03";
        //     }else if(this.addType===2){
        //         // 服务预约
        //          this.prefix = "s41"
        //          this.objectApi = "ServiceAppointment"
        //          this.relationFieldId ="ffe21sappointment083"
        //         this.objectName= this.$i18n.t('label.projectManagement.service.appointment')
                //服务预约
        //     }
        //     this.dialogVisible = false;
        //     this.$nextTick(()=>{
        //         this.$refs.createEditObj.add();
        //     })
        // },
        // 点击展示当前成员下的任务明细
        showCurRow(index, row) {
            if(!row.tasknum){
                // 无任务时不继续执行方法
                return;
            }
            this.userpage[row.id]=1;
            // 点击时首先关闭其他成员，再展开当前成员，确保展开的只有一个
            // this.tableList.forEach((item,key) => {
            //     if(index!==key){
            //         this.tableList[key].show = false;//除了当前点击的其余成员都关闭
            //     }
            // });
            this.tableList[index].loading = true;
            this.tableList[index].show = !this.tableList[index].show;
            // 调取接口，获取成员的任务明细
            let params = {
                projectId:this.$route.query.proId?this.$route.query.proId:this.projectId,
                startDate: this.startDate,
                endDate: this.endDate,
                memberId: row.memberId,
                taskPagination:this.userpage[row.id].toString(),
                taskPageSize:this.tasksize.toString(),
                // dataState:''
            };
            if (row.tasknum > 0 && row.show) { //任务数大于0点击时调取接口
                this.getTaskDetail(index, params);
            }
           // this.addIndexMemberId = row.memberId;
        },
        // 获取成员的任务明细
        getTaskDetail(index, params,add) {
            projectApi.getMemberTask(params).then((res) => {
                this.tasksmore[params.memberId]=res.data.isLast;
                let tasksarr= res.data.list.map(item => {
                    //任务条实际的开始结束时间，eg，时间选择器是3.1到3.31，后端返回任务的开始结束是3.15-4.20，则次时间段下任务的开始时间为3.15结束时间为3.31
                    let continutime = 0, otherDays = 0, dataStart = item.startDate, dataEnd = item.endDate, diffStartDays = 0, diffEndDays = 0, _x = 0, dragx = 0;
                    diffStartDays = this.$moment(dataStart).diff(this.$moment(this.startDate), "days"); //计算任务开始时间和时间选择器开始时间之间的时间差，是否大于0，大于0则任务开始时间在时间选择器内
                    diffEndDays = this.$moment(this.endDate).diff(this.$moment(dataEnd), "days");
                    dataStart = diffStartDays > 0 ? dataStart : this.startDate;
                    dataEnd = diffEndDays > 0 ? dataEnd : this.endDate;
                    let realContinuDate=this.$moment(dataEnd).diff(this.$moment(dataStart), "days")+1;
                    let minW=0
                    if (this.checkTimeType === "date") {
                        // 天
                        // 宽度计算
                        continutime = this.$moment(dataEnd).diff(this.$moment(dataStart), "days") + 1;//每条任务开始时间与结束时间的相差多久
                        // 距离左边的距离
                        let _diff = this.$moment(dataStart).diff(this.$moment(this.startDate), "days"); //获取任务的开始日期和视图的开始日期之间的时间差，初始化的时候根据任务的开始结束时间给任务初始化宽度
                        _x = _diff * this.curWidth; //距离左边的距离
                        // 拖拽距离
                        dragx = this.curWidth;
                        // 最小宽度
                        minW=this.curWidth;
                    } else if (this.checkTimeType === "week") {
                        // 周
                        // let startWeeksDays=this.$moment(dataStart).startOf('weeks').add(1, 'days').format("YYYY-MM-DD"); //中国每周的第一天
                        // let diffweek = this.$moment(startWeeksDays).diff(this.$moment(dataStart), "weeks");
                        // 宽度计算
                        continutime = this.$moment(dataEnd).diff(this.$moment(dataStart), "weeks");
                        let _diff = this.$moment(dataEnd).diff(this.$moment(dataStart), "days")+1;
                        _diff = _diff <=7 ? _diff : _diff % 7;
                        otherDays = _diff * this.curWidth / 7;
                        // 距离左边的距离
                        let diffStartWeeksDays = this.$moment(dataStart).diff(this.$moment(this.startDate), "days");
                        let diffOther = diffStartWeeksDays < 7 ? diffStartWeeksDays : diffStartWeeksDays % 7;
                        _x = parseInt(diffStartWeeksDays / 7) * this.curWidth + diffOther * this.curWidth / 7; //距离左边的距离 
                        dragx = this.curWidth / 7;
                        // diffEndDays = this.$moment(this.endDate).diff(this.$moment(dataEnd), "days") ;
                        // 最小宽度
                        minW=this.curWidth/7;
                    } else if (this.checkTimeType === "month") {
                        // 月
                        let _monthDays = this.$moment(dataStart, "YYYY-MM").daysInMonth(); //获取当前月有多少天
                        let _endmonthDays = this.$moment(dataStart, "YYYY-MM").daysInMonth(); //获取结束日期所在月有多少天，改变宽度时使用
                        continutime = this.$moment(dataEnd).diff(this.$moment(dataStart), "month");
                        let _diff = this.$moment(dataEnd).diff(this.$moment(dataStart), "days")+1;
                        _diff = _diff < _monthDays ? _diff : _diff % _monthDays;
                        otherDays = _diff * this.curWidth / _monthDays;
                        // 距离左边的距离
                        let diffStartWeeksDays = this.$moment(dataStart).diff(this.$moment(this.startDate), "days");
                        let diffOther = diffStartWeeksDays < _monthDays ? diffStartWeeksDays : diffStartWeeksDays % _monthDays;
                        _x = parseInt(diffStartWeeksDays / _monthDays) * this.curWidth + diffOther * this.curWidth / _monthDays; //距离左边的距离 
                        dragx = this.curWidth / _endmonthDays;
                        // 最小宽度
                        minW=this.curWidth/_endmonthDays;
                    }
                    let _w = this.curWidth * continutime + otherDays; //任务条宽度计算s
                    return {
                        x: _x,
                        w: _w,
                        dragx: dragx,
                        minW: minW,
                        taskName: item.taskName,
                        endDate: item.endDate,
                        principal: item.principal,
                        taskId: item.taskId,
                        continueDate: item.continueDate,
                        continue_date: item.continue_date,
                        startDate: item.startDate,
                        realContinuDate:realContinuDate,
                        objectApi:item.objectApi,
                        workHour:item.workHour,
                        changeHour:item.workHour,//拷贝一份工作小时数修改时使用，防止为点击确认按钮时原数据被修改
                    };
                });
                this.tableList[index].loading = false;
                if(add&&add=='add'){
                    this.tableList[index].children= this.tableList[index].children.concat(tasksarr)
                }else{
                   this.tableList[index].children= tasksarr
                }
            })   
        },
        // 任务点击进详情
        handleTask(id) {
            this.$router.push({ path: `/commonObjects/detail/${id}/DETAIL`,query:{sign:'back'}});
        },
        // 点击任务名称滚动条滚动到指定位置
        setScroll(pindex,cindex){
            let _curLeft = this.tableList[pindex].children[cindex].x;
            this.$refs.resourceutilTableholder.scrollLeft = _curLeft-300;
        },
        // 保存成功回调
        save(){
            this.getRightView('isDrag');
            this.$emit("refreshRelatedList");
        },
        // 拖拽保存方法
        dragSave(params) {
            projectApi.save(params).then((res) => {
                if(res.data[0].isSaveSuccess){
                        this.$message.success(this.$i18n.t('label.projectManagement.update.succeeded'))//更新成功
                    }else{
                        this.$message.error(res.data[0].errormessage)
                    }
                    this.getRightView('isDrag');
            }).catch(()=>{
                // 失败将视图恢复到更新前状态
                this.tableList = cloneDeep(this.newMap); 
            })
        },
    },
    created() {
    },
    mounted() { 
        // 页面初始化时默认滑动到今天所在位置
        this.$nextTick(()=>{
            this.$refs.resourceutilTableholder.scrollLeft = this.todayLeft.split("px")[0]-300;
        })
    }
};
</script>
<style lang='scss'>
.projectDropdown {
    .el-dropdown-menu__item {
        span {
            display: flex;
            align-items: center;
        }
    }
    .el-radio-icon {
        border: 1px solid #dcdfe6;
        border-radius: 100%;
        width: 14px;
        height: 14px;
        background-color: #fff;
        position: relative;
        cursor: pointer;
        display: inline-block;
        box-sizing: border-box;
    }
    .el-radio-icon:after {
        width: 4px;
        height: 4px;
        border-radius: 100%;
        background-color: #fff;
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) scale(0);
        transition: transform 0.15s ease-in;
    }
    .el-radio-icon.is-checked {
        border-color: #409eff;
        background: #409eff;
    }

    .el-radio-icon.is-checked:after {
        transform: translate(-50%, -50%) scale(1);
    }
    img {
        display: block;
    }
}
.sorcePoper {
    background: #fff !important;
    color: #333 !important;
    line-height: 20px;
    border: 1px solid #e6e6e6;
    .hoverBox {
        padding: 5px;
    }
    // 内三角样式
    &.el-tooltip__popper[x-placement^="top"] .popper__arrow {
        border-top-color: #e6e6e6 !important;
    }
    // 外三角样式
    &.el-tooltip__popper[x-placement^="top"] .popper__arrow::after {
        border-top-color: #ffffff !important;
    }
    &.el-tooltip__popper[x-placement^=right] .popper__arrow {
        border-right-color: #e6e6e6!important;
    }
    &.el-tooltip__popper[x-placement^=right] .popper__arrow::after{
        border-right-color: #ffffff !important;
    }
}
.el-popover.taskPop{
    text-align: left;
    .flexTask{
        display: flex;
        align-items: center;
        justify-content: space-between;
        &{
            margin-bottom:15px ;
        }
        &:first-of-type{
            line-height: 40px;
            border-bottom: 1px solid #e6e6ee;
        }
        .el-input{
            width: 100px;
            height: 30px;
            .el-input__inner{
                 height: 30px;
            }
        }
        .hour{
            margin-left: 4px;
        }
    }
    &.el-popper[x-placement^=top]{
        border: solid 1px #e6e6e6;
    }
    &.el-popper[x-placement^=top] .popper__arrow{
        border-top-color: #e6e6e6;
    }
    &.el-popper[x-placement^=bottom]{
        border: solid 1px #e6e6e6;
    }
    &.el-popper[x-placement^=bottom] .popper__arrow{
        border-bottom-color: #e6e6e6;
    }
}
</style>
<style lang='scss' scoped>
$mainHeight: calc(100vh - 200px);
$mainLineHeight: calc(100vh - 220px);
$mainHeightt: calc(100vh - 150px);
.sourceManage {
    height: calc(100% - 60px);
}
.noData{
    width:100%;
    height: $mainLineHeight;
    position:fixed;
    top:207px;
    background:#fff;
    z-index: 999;
    padding-top: 50px;
    left:200px;
    .div_temporarily_no_data{
        margin-left: -200px;
    }
}
// 天-视图最新宽度40，月/周最小宽度140
p {
    margin: 0;
}
.zps-resource {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 0;
    top: 0;
    left: 0;
}
.resource-navigation-row {
    background: #f3f2f2;
    height: 50px;
    width: 100%;
    flex: 0 0 auto;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 6;
    padding: 0 10px;
}
.resourceutilContainer {
    background: #fff;
    width: 100%;
    flex: 1 1 0%;
    min-height: $mainHeightt;
}
.resourceutilTableholder {
    display: flex;
    width: 100%;
    height: 100%;
    // height: $mainHeight;
    flex-flow: column;
    overflow: auto;
    align-items: flex-start;
    position: relative;
}
.dateholderHeader {
    position: -webkit-sticky;
    position: sticky;
    z-index: 3;
    top: 0;
    display: flex;
    align-items: stretch;
    min-width: 100%;
    flex: 0 0 auto;
}
.resSearchSticky {
    position: -webkit-sticky;
    position: sticky;
    height: 0;
    top: 0;
    width: 100%;
    z-index: 5;
    left: 0;
    flex: 0 0 auto;
}
.res-today-line {
    position: absolute;
    width: 1px;
    height:  $mainLineHeight;
    border-right: #006dcc dashed 1px;
    z-index: 0;
    top: 0;
    transition: 0.3s;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}
.monthColumn {
    width: 100px;
    border-bottom: 1px solid #e3e7eb;
    border-right: 1px solid #fafafa;
    background: #fcfcfc;
    color: #0055a0;
    display: inline-flex;
    flex-flow: column;
    align-items: stretch;
    justify-content: center;
    box-sizing: border-box;
}
.stickyT0 {
    position: sticky;
    top: 0px;
}
.txtBoldBlue {
    font-size: 13px;
    font-weight: bold;
    color: #0383bc;
}
.bugoflowh,
.ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.fs11px {
    font-size: 12px;
    color: #006dcc;
}
.pt4 {
    padding-top: 4px;
}
.resourceutilContainer .weekNameColumn {
    min-width: 140px; //每天20
}
.resourceutilContainer.days .weekNameColumn {
    min-width: 40px;
}
.resworkdaybg {
    background: #fff;
}
.dateholderHeader {
    .weekendDay {
        background: #eaf5ff;
    }
}
.resourceutilContainer.retu {
    .resource-list-content {
        .weekendDay {
            .res-alloc-bar {
                background: #f3f2f2;
            }
        }
    }
}

.weekNameColumn {
    border-bottom: 1px solid #e6e6e6;
    position: relative;
    padding: 0px;
    justify-content: center;
    display: inline-flex;
    flex-flow: column;
    box-sizing: border-box;
    flex: 1;
}
.weekNameColumn:before {
    content: "";
    position: absolute;
    background: #e6e6e6;
    width: 1px;
    top: 0;
    left: 0;
    pointer-events: none;
    height:  $mainLineHeight;
}
.resworkdaycount {
    color: #333;
    font-size: 12px;
    padding: 0 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    .blueColor {
        color: #0383bc;
    }
}
.resource-list-content {
    // display: contents;
    width: 100%;
}
.stickySpecial{
    display:flex;
    position: sticky;
    left: 0;
    width: 200px;
    background: #fff;
    z-index: 4;
}
.resource-list-parent {
    flex: 0 0 auto;
    width: 100%;
}
.ruflexRowContainerTop:hover {
    & {
        background: #eaf4ff !important;
        .monthnameColumn,
        .ruUsernameHolder {
            background: #eaf4ff !important;
        }
    }
}
.retu .ruflexRowContainerTop:hover {
    .res-alloc-bar {
        background: #eaf4ff !important;
    }
    .res-alloc-bar.green {
        background: #cde7db !important;
    }
}
.ruflexRowContainer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    clear: both;
    min-width: 100%;
    flex: 0 0 auto;
    min-height: 50px;
}
.resource-list-child {
    flex: 0 0 auto;
    min-width: 100%;
    position: relative;
}
.rusearchholder {
    z-index: 5;
    width: 200px;
    background: #fff;
    display: flex;
    height: 45px;
    border-bottom: 1px solid #e6e6e6;
    border-right: 1px solid #fafafa;
    .search-text {
        height: 50px;
        line-height: 50px;
        ::v-deep .el-input__inner {
            border: none;
        }
    }
}
.stickyL0 {
    position: sticky;
    left: 0px;
}
.rusearchholder,
.ruUsernameHolder,
.ruDaycolumn,
.ruDaycolumntask,
.monthnameColumn,
.datelessColumn {
    min-height: 50px;
    height: 100%;
}
.borderContainer {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    clear: both;
    min-width: 100%;
    flex: 0 0 auto;
    min-height: 50px;
    .borderRight {
        flex: 1;
        height: 50px;
        border-right: 1px solid #e6e6e6;
    }
}
.ruUsernameHolder {
    display: flex;
    z-index: 4;
    background: #fff;
    vertical-align: middle;
    width: 200px;
    min-height: 50px;
    align-items: center;
    flex: 0 0 auto;
    &:hover {
        .res-taskcount {
            color: red;
        }
    }
    box-shadow: inset 0 0px 7px #e6e6e6;
}
.ruUsernameHolder .ruContainer {
    cursor: pointer;
    display: flex;
    align-items: center;
    width: calc(100% - 30px);
    padding-left: 30px;
    height: 100%;
}
.fdcolumn,
.fdirCol {
    flex-direction: column;
}
.flex {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
}
.minw0 {
    min-width: 0;
}
.ml10 {
    margin-left: 10px;
}
.ruUsernameHolder .ruContainer > img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
}
.res-taskcount {
    font-size: 12px;
    color: #333;
    padding-top: 3px;
}
.res-username {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 13px;
    display: inline-block;
    vertical-align: top;
    width: 100%;
}
.res-taskcount > i {
    position: relative;
    top: 0;
    margin-left: 3px;
    display: inline-block;
}
.mh55 {
    max-height: 50px;
}
.monthnameColumn {
    padding: 20px;
    width: 100px;
    flex: 0 0 auto;
    background: #fcfcfc;
    position: relative;
    border-right: 1px solid #fafafa !important;
}
.aic,
.alignCenter,
.aicenter {
    align-items: center;
}
.flex {
    display: flex;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
}
.bugoflowh,
.ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.resdetail-span {
    position: absolute;
    font-size: 12px;
    bottom: 4px;
    left: 0;
    right: 0;
    text-align: center;
}
.ruplusicon {
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 20px;
    height: 20px;
    cursor: pointer;
    border-radius: 50%;
    background: #fff;
    display: none;
}
.el-icon-circle-plus-outline {
    color: #8f8f8f;
    &:before {
        font-size: 20px;
    }
}
.res-rangecol-bar {
    width: 100%;
    height: 10px;
    border-radius: 10px;
    overflow: hidden;
    display: flex;
    flex: 1;
    background: #f3f2f2;
}
.res-rangecol-bar > span {
    height: 100% !important;
}
.underallocated {
    background: #4ca57d;
}
.derallocated {
    background: #d3635b;
}
.ruDaycolumn {
    padding: 20px 0px;
    flex: 1;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 140px;
    overflow: hidden;
    &:hover {
        .ruplusicon {
            display: block;
        }
    }
}
.resourceutilContainer.days .ruDaycolumn {
    min-width: 56px;
}
.res-alloc-bar {
    height: 10px;
    width: 100%;
    position: absolute;
    background: #eee;
}
.resourceutilContainer.retu .res-alloc-bar {
    height: 50px;
    background: #ffffff;
}
.bugoflowh,
.ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}
.resdetail-span {
    position: absolute;
    font-size: 12px;
    bottom: 0;
    left: 0;
    right: 0;
    text-align: center;
}
.res-alloc-bar > span {
    position: absolute;
    left: 0;
}
.res-alloc-bar.red span {
    background: #d3635b;
}
.res-alloc-bar.green {
    background: #cde7db !important;
    span {
        background: #4ca57d !important;
    }
}
.resbdrRight {
    // border-right: 1px solid #dddbda;
    // border-bottom: 1px solid #eeeeee;
}
.dragOne {
    // min-width: 100%;
    // position: relative;
    .dragItem {
        height: 50px;
        position: relative;
        // overflow-x: hidden;
        .taskPop{
            position: absolute;
            padding: 10px;
            background: #fff;
            border: 1px solid #ccc ;
        }
        ::v-deep {
            .handle-ml,
            .handle-mr {
                opacity: 0;
            }
        }
        &:hover {
            ::v-deep {
                .handle-ml,
                .handle-mr {
                    opacity: 1;
                }
            }
        }
        .dragItemInner {
            position: absolute;
            width: 100%;
            height: 100%;
            background: #dddbda;
            cursor: move;
        }
    }
}
.resource-navigation-row-left {
    display: flex;
    .span1,
    .span2 {
        margin-left: 20px;
        .el-dropdown-link {
            cursor: pointer;
            //color: #409eff;
            font-size: 14px;
            font-weight: 500;
            color: #080707;
            line-height: 21px;
        }
        .el-icon-arrow-down {
            font-size: 12px;
        }
    }
}
.resource-navigation-row-right {
    position: absolute;
    right: 35px;
    display: flex;
    justify-content: center; /* 相对父元素水平居中 */
    align-items: center; /*  子元素相对父元素垂直居中 */
    .right-1 {
        span {
            padding: 0 20px;
            cursor: pointer;
            &.is-active {
                color: #006dcc;
                font-weight: bold;
            }
            &:hover {
                color: #006dcc;
                font-weight: bold;
            }
        }
    }
    .right-2 {
        margin-left: 45px;
    }
}
.ruflexRowContainer.droppableOne:first-of-type .resNmParent {
    // box-shadow: inset 0px 0px 8px -2px #ccc;
    box-shadow: inset 0 2px 5px #e6e6e6;
    // border-bottom: 1px solid #e6e6e6;
}
// .ruflexRowContainer.droppableOne:last-of-type .resNmParent {
//    // box-shadow: inset 0 0px 5px #e6e6e6;
// }

.resNmParent {
    display: flex;
    z-index: 3;
    background: #fff;
    width: 200px;
    align-items: center;
    min-height: 50px;
    height: 100%;
    padding-right: 15px;
    padding-left: 10px;
    cursor: pointer;
    // overflow: hidden;
    border: 1px solid #e6e6e6;
    border-top: none;
    white-space: nowrap;
}
.resNmParent > div {
    height: 20px;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    line-height: 1.5;
}
.resNmParent > div > span {
    padding-right: 3px;
    color: #666;
    font-size: 12px;
}
.hoverTaskBox {
    .hoverTaskBoxTitle {
        font-weight: 500;
        color: #080707;
        line-height: 21px;
    }
}
.ruDaycolumntask {
    position: relative;
    flex: 1;
}
.droppableOne{
    &:hover{
        background: #eaf4ff;
        .resNmParent,.monthnameColumn{
            background: #eaf4ff;
        }
    }
}
//拖动条的样式
::v-deep .vdr {
    touch-action: none;
    border: 1px solid #dddbda;
    border-radius: 4px;
}
::v-deep .handle {
    width: 10px;
    height: 10px;
    border: none;
}
// 热图

.resourceutilContainer.retu .ruDaycolumn {
    padding: 0px;
    border-bottom: 1px solid #dddbda;
}
.resourceutilContainer.retu .res-alloc-bar > span {
    right: 0;
    bottom: 0;
}
.taskTxt{
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.pro-icon{
    width: 94px;
    height: 68px;   
}
::-webkit-scrollbar{width:0;}
.loadingmore{
    display: flex;
    align-items: center;
    justify-content: center;
    color:#0383bc;
}
</style>