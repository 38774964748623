
<!-- rangeTime -->
<template>
    <div class="rangeTime">
        <div>
            <el-tooltip  effect="dark" :content="tipContentPre" placement="top-start">
                <span @click="preWeek" class="el-icon-arrow-left checkIcon"></span>
            </el-tooltip>
        </div>
        <!-- 天 -->
        <div class="days" v-show="checkTimeType==='date'">
            <el-date-picker v-model="checkDay" type="daterange" range-separator="-" @change="dayChange" :clearable="false" :editable="false" :picker-options="pickerSDateOptions">
            </el-date-picker>
        </div>
        <!-- 周选择 -->
        <div class="weekblock" v-show="checkTimeType==='week'">
            <div class="pickerContainer pickerContainer1">
                <el-date-picker ref="getStartWeek" popper-class="projectRangeAllTime" class="weekDate" type="week" :editable="false" v-model="startWeek" @change="startWeekChange" format="yyyy年第WW周"
                    :picker-options="pickerStartOptions">
                </el-date-picker>
                <el-input class="week-picker" v-model="weekTime1" prefix-icon="el-icon-date"></el-input>
            </div>
            <span class="jiange">-</span>
            <div class="pickerContainer pickerContainer2">
                <el-date-picker ref="getEndWeek" popper-class="projectRangeAllTime" class="weekDate" type="week" :editable="false" v-model="endWeek" @change="endWeekChange" format="yyyy年第WW周"
                    :picker-options="pickerEndOptions">
                </el-date-picker>
                <el-input class="week-picker" v-model="weekTime2"></el-input>
            </div>
        </div>
        <!-- 月选择 -->
        <div class="month" v-show="checkTimeType==='month'">
            <el-date-picker @change="monthChange" v-model="monthVal" format="yyyy-MM-dd" type="monthrange" range-separator="-" :clearable="false" :editable="false"></el-date-picker>
        </div>
        <div>
            <el-tooltip  effect="dark" :content="tipContentNex" placement="top-start">
                <span @click="nextWeek" class="el-icon-arrow-right checkIcon"></span>
            </el-tooltip>
        </div>
    </div>
</template>

<script>

export default {
    name: "rangeTime",
    components: {},
    data () {
        let that = this;
        return {
            allTimeArr: [],//日期数组
            startWeek: '',
            endWeek: '',
            checkDay: "",
            monthVal: [],
            weekTime1: "",//周选择展示日期
            weekTime2: "",//周选择展示日期
            firstNum: 1,
            tipContentPre:  this.$i18n.t('label.projectManagement.next.page.before'),//"前一页",
            tipContentNex:  this.$i18n.t('label.projectManagement.next.page'),//"后一页",
            startWeekEndDay: "",//开始周的结束日期
            endWeekStartDay: "",//结束周的开始日期
            timeStartAndEnd: "",//时间选择器的开始日期和结束日期
            pickerSDateOptions:{
                firstDayOfWeek: 1,
            },
            //禁用日期范围
            pickerStartOptions: {
                firstDayOfWeek: 1,
                disabledDate (time) {
                    if (that.endWeekStartDay) {
                        return time.getTime() > new Date(that.endWeekStartDay).getTime() - 1000 * 3600 * 24;
                    }
                },
            },
            pickerEndOptions: {
                firstDayOfWeek: 1,
                disabledDate (time) {
                    if (that.startWeekEndDay) {
                        return (
                            time.getTime() < new Date(that.startWeekEndDay).getTime()
                        );
                    }
                }
            }
        };
    },
    props: {
        checkTimeType: {
            type: String,
            default: 'date'
        },
    },
    watch: {
        checkTimeType: {
            handler (val) {
               // 
                if (val) {
                    this.getDefaultDate(); //编辑时加载数据
                }
            },
            immediate: true
        }
    },
    computed: {},
    methods: {
        dayChange () {
            this.allTimeArr = this.getFullDate(this.$moment(this.checkDay[0]), this.$moment(this.checkDay[1]), 1);
            this.timeStartAndEnd = this.checkDay;
            this.$emit('getDateArry', this.allTimeArr, this.timeStartAndEnd);
        },
        startWeekChange () {
            this.getWeekTime(this.$moment(this.startWeek).format("YYYY-MM-DD"), 1);
            if (!this.weekTime2) { //未选结束周自动调起结束时间选择器
                this.$refs.getEndWeek.focus();
            }
            this.allTimeArr = this.getFullDate(this.$moment(this.weekTime1), this.$moment(this.weekTime2), 2);
            this.timeStartAndEnd = [this.weekTime1, this.weekTime2];
            this.$emit('getDateArry', this.allTimeArr, this.timeStartAndEnd);
        },
        endWeekChange () {
            this.getWeekTime(this.$moment(this.endWeek).format("YYYY-MM-DD"), 2);
            if (!this.weekTime1) { //未选开始周自动调起开始时间选择器
                this.$refs.getStartWeek.focus();
            }
            this.allTimeArr = this.getFullDate(this.$moment(this.weekTime1), this.$moment(this.weekTime2), 2);
            this.timeStartAndEnd = [this.weekTime1, this.weekTime2];
            this.$emit('getDateArry', this.allTimeArr, this.timeStartAndEnd);
        },
        getWeekTime (time, type) {
            let weekOfday = this.$moment(time).format('d');//计算今天是这周第几天
            let last_monday_z = this.$moment(time).subtract(weekOfday - 1, 'days').format("YYYY-MM-DD");//中国周一日期
            let last_sunday_z = this.$moment(time).subtract(-(7 - weekOfday), 'days').format("YYYY-MM-DD");//中国周日日期
            let last_monday_us = this.$moment(time).startOf('weeks').format("YYYY-MM-DD");//国外周日日期
            let last_sunday_us = this.$moment(time).endOf('weeks').format("YYYY-MM-DD");//国外周六日期
            if (type === 1) {
                this.weekTime1 = this.firstNum === 1 ? last_monday_z : last_monday_us;
                this.startWeekEndDay = this.firstNum === 1 ? last_sunday_z : last_sunday_us;
            } else {
                this.weekTime2 = this.firstNum === 1 ? last_sunday_z : last_sunday_us;
                this.endWeekStartDay = this.firstNum === 1 ? last_monday_z : last_monday_us;
            }

        },
        monthChange () {
          let YY = this.$moment(this.monthVal[1]).format("YYYY");//当前点击年
          let MM = this.$moment(this.monthVal[1]).format("M");//当前点击月
          var DD = new Date(YY, MM, 0).getDate(); // 当前点击月的最后一天
          this.allTimeArr = this.getFullDate(this.$moment(this.monthVal[0]), this.$moment(this.monthVal[1]), 3);
          this.timeStartAndEnd = this.monthVal;
          let currentMonthEnd=`${YY}-${MM}-${DD}`;
          this.monthVal[1]=currentMonthEnd;
          this.$emit('getDateArry', this.allTimeArr, this.timeStartAndEnd);
        },
        // 前一页
        preWeek () {
            let start = "", end = "";
            if (this.checkTimeType === 'date') {
                start = this.$moment(this.checkDay[0]).subtract(1, 'month').startOf('months').format("YYYY-MM-DD");
                end = this.$moment(this.checkDay[0]).subtract(1, 'month').endOf('months').format("YYYY-MM-DD");
                this.checkDay = [start, end];
                this.allTimeArr = this.getFullDate(this.$moment(this.checkDay[0]), this.$moment(this.checkDay[1]), 1);
                this.timeStartAndEnd = this.checkDay;
                this.$emit('getDateArry', this.allTimeArr, this.timeStartAndEnd);
            } else if (this.checkTimeType === 'week') {
                if (!this.weekTime1) { //未选开始周自动调起开始时间选择器
                    this.$refs.getStartWeek.focus();
                    return
                }
                if (!this.weekTime2) { //未选结束周自动调起结束时间选择器
                    this.$refs.getEndWeek.focus();
                    return;
                }
                this.endWeek = this.$moment(this.weekTime1).add(-1, 'days');
                this.weekTime2 = this.endWeek.format("YYYY-MM-DD"); //往后推5周，即35天，减掉当天
                this.startWeek = this.$moment(this.weekTime1).add(-35, 'days');
                this.weekTime1 = this.startWeek.format("YYYY-MM-DD");
                this.startWeekEndDay = this.$moment(this.weekTime1).add(6, 'days').format("YYYY-MM-DD");
                this.endWeekStartDay = this.$moment(this.weekTime2).add(-6, 'days').format("YYYY-MM-DD");
                this.allTimeArr = this.getFullDate(this.$moment(this.weekTime1), this.$moment(this.weekTime2), 2);
                this.timeStartAndEnd = [this.weekTime1, this.weekTime2];
                this.$emit('getDateArry', this.allTimeArr, this.timeStartAndEnd);
            } else if (this.checkTimeType === 'month') {
                start = this.$moment(this.monthVal[0]).subtract(6, 'month').startOf('months').format("YYYY-MM-DD");
                end = this.$moment(this.monthVal[1]).subtract(6, 'month').endOf('months').format("YYYY-MM-DD");
                this.monthVal = [start, end];
                this.allTimeArr = this.getFullDate(this.$moment(this.monthVal[0]), this.$moment(this.monthVal[1]), 3);
                this.timeStartAndEnd = this.monthVal;
                this.$emit('getDateArry', this.allTimeArr, this.timeStartAndEnd);
            }
        },
        // 后一页
        nextWeek () {
           // 
            let start = "", end = "";
            if (this.checkTimeType === 'date') {
                start = this.$moment(this.checkDay[1]).subtract(-1, 'month').startOf('months').format("YYYY-MM-DD");
                end = this.$moment(this.checkDay[1]).subtract(-1, 'month').endOf('months').format("YYYY-MM-DD");
                this.checkDay = [start, end];
                this.allTimeArr = this.getFullDate(this.$moment(this.checkDay[0]), this.$moment(this.checkDay[1]), 1);
                this.timeStartAndEnd = this.checkDay;
                this.$emit('getDateArry', this.allTimeArr, this.timeStartAndEnd);
            } else if (this.checkTimeType === 'week') {
                if (!this.weekTime1) { //未选开始周自动调起开始时间选择器
                    this.$refs.getStartWeek.focus();
                    return
                }
                if (!this.weekTime2) { //未选结束周自动调起结束时间选择器
                    this.$refs.getEndWeek.focus();
                    return;
                }
                this.startWeek = this.$moment(this.weekTime2).add(1, 'days');
                this.weekTime1 = this.startWeek.format("YYYY-MM-DD");
                this.endWeek = this.$moment(this.weekTime1).add(34, 'days');
                this.weekTime2 = this.endWeek.format("YYYY-MM-DD"); //往后推5周，即35天，减掉当天
                this.startWeekEndDay = this.$moment(this.weekTime1).add(6, 'days').format("YYYY-MM-DD");
                this.endWeekStartDay = this.$moment(this.weekTime2).add(-6, 'days').format("YYYY-MM-DD");
                this.allTimeArr = this.getFullDate(this.$moment(this.weekTime1), this.$moment(this.weekTime2), 2);
                this.timeStartAndEnd = [this.weekTime1, this.weekTime2];
                this.$emit('getDateArry', this.allTimeArr, this.timeStartAndEnd);
            } else if (this.checkTimeType === 'month') {
                start = this.$moment(this.monthVal[0]).subtract(-6, 'month').startOf('months').format("YYYY-MM-DD");
                end = this.$moment(this.monthVal[1]).subtract(-6, 'month').endOf('months').format("YYYY-MM-DD");
                this.monthVal = [start, end];
                this.allTimeArr = this.getFullDate(this.$moment(this.monthVal[0]), this.$moment(this.monthVal[1]), 3);
                this.timeStartAndEnd = this.monthVal;
                this.$emit('getDateArry', this.allTimeArr, this.timeStartAndEnd);
            }
        },
        // 获取周几
        getweek (val) {
            switch (val) {
                case 1:
                    return  this.$i18n.t('monday');//"星期一 ";
                case 2:
                    return this.$i18n.t('tuesday');//"星期二 ";
                case 3:
                    return  this.$i18n.t('wednesday');//"星期三 ";
                case 4:
                    return  this.$i18n.t('thursday');//"星期四 ";
                case 5:
                    return  this.$i18n.t('friday');//"星期五 ";
                case 6:
                    return  this.$i18n.t('saturday') ;//"星期六 ";
                case 0:
                    return  this.$i18n.t('sunday');//"星期日 ";
            }
        },
        // 获取两个日期之间所有的日期，并以数组的方式返回type=1时返回日期，type=2时返回周，type=3时返回月
        getFullDate (startDate, endDate, type) {
            let dates = [], curWeek = "";
            if (type === 1) {
                // 天
                curWeek = this.getweek(startDate.day())
                dates.push([startDate.format('YYYY-MM-DD'), curWeek]);
                if(startDate.format('YYYY-MM-DD')!=endDate.format('YYYY-MM-DD')){
                    // 选择非同一天
                    startDate = startDate.add(1, 'days');
                    curWeek = this.getweek(startDate.day());
                    while (startDate.format('YYYY-MM-DD') !== endDate.format('YYYY-MM-DD')) {
                        dates.push([startDate.format('YYYY-MM-DD'), curWeek]);
                        startDate = startDate.add(1, 'days');
                        curWeek = this.getweek(startDate.day());
                    }
                    curWeek = this.getweek(endDate.day())
                    dates.push([endDate.format('YYYY-MM-DD'), curWeek]);
                }
            } else if (type === 2) {
                // 周
                let nextDate = startDate;
                let start = startDate.format('YYYY-MM-DD');
                startDate = startDate.add(6, 'days');
                let end = startDate.format('YYYY-MM-DD');
                while (startDate.format('YYYY-MM-DD') !== endDate.format('YYYY-MM-DD')) {
                    dates.push([start, end]);
                    nextDate = startDate.add(1, 'days');
                    start = nextDate.format('YYYY-MM-DD');
                    startDate = nextDate.add(6, 'days');
                    end = startDate.format('YYYY-MM-DD');
                }
                dates.push([start, end]);
            } else if (type === 3) {
                // 月 
                dates.push(startDate.format('YYYY-MM-DD'));
            if(startDate.format('YYYY-MM-DD')!=endDate.format('YYYY-MM-DD')){
                startDate = startDate.add(1, 'month');
                while (startDate.format('YYYY-MM') !== endDate.format('YYYY-MM')) {
                    dates.push(startDate.format('YYYY-MM-DD'));
                    startDate = startDate.add(1, 'month');
                }
                dates.push(startDate.format('YYYY-MM-DD'));
            }
            }
            return dates;
        },
        // 获取i周的周一和周日日期，并以数组的方式返回。
        getNextWeek (total) {
            let dates = [];
            for (let i = 0; i < total; i++) {
                let weekOfDay = parseInt(this.$moment().format('d'));//计算今天是这周第几天
                let next_monday_z = this.$moment().add((7 - weekOfDay) + 7 * (i - 1) + 1, 'days').format('YYYY-MM-DD');//周一日期
                let next_sunday_z = this.$moment().add((7 - weekOfDay) + 7 * i, 'days').format('YYYY-MM-DD');//周日日期
                let last_monday_us = this.$moment().add((7 - weekOfDay) + 7 * (i - 1), 'days').format('YYYY-MM-DD');//国外周日日期
                let last_sunday_us = this.$moment().add((7 - weekOfDay) + 7 * i - 1, 'days').format('YYYY-MM-DD');//国外周六日期
                let start = this.firstNum === 1 ? next_monday_z : last_monday_us;
                let end = this.firstNum === 1 ? next_sunday_z : last_sunday_us;
                dates.push([start, end])
            }
            return dates
        },
        // 获取默认数据
        getDefaultDate () {
            if (this.checkTimeType === 'date') {
                // 获取最近一个月的日期
                let dateStart = this.$moment().startOf('month');  //当前月的开始日期
                let dateEnd = this.$moment().endOf('month'); //当前月的结束日期
                this.checkDay = [dateStart, dateEnd];  //天默认时间
                this.timeStartAndEnd = [new Date(dateStart),new Date(dateEnd)];
                this.allTimeArr = this.getFullDate(this.$moment(this.checkDay[0]), this.$moment(this.checkDay[1]), 1);
            } else if (this.checkTimeType === 'week') {
                // 获取最近5周
                this.startWeek = new Date(this.getNextWeek(5)[0][0].replace(/-/g, '/'));
                this.endWeek = new Date(this.getNextWeek(5)[4][1].replace(/-/g, '/'));
                this.weekTime1 = this.getNextWeek(5)[0][0];
                this.weekTime2 = this.getNextWeek(5)[4][1];
                this.startWeekEndDay = this.getNextWeek(5)[0][1];
                this.endWeekStartDay = this.getNextWeek(5)[4][0];
                this.timeStartAndEnd = [this.weekTime1, this.weekTime2];
                this.allTimeArr = this.getFullDate(this.$moment(this.weekTime1), this.$moment(this.weekTime2), 2);
            } else if (this.checkTimeType === 'month') {
                // 获取最近半年
                let start = this.$moment().startOf('month');//当前月的开始日期
                let end = this.$moment().subtract(-5, 'month');//半年后的结束日期
                let end1 = this.$moment().subtract(-5, 'month');
                this.monthVal = [start.toDate(), end1.endOf('month').toDate()];
                this.timeStartAndEnd = this.monthVal;
                this.allTimeArr = this.getFullDate(start, end, 3);
            }
            this.$emit('getDateArry', this.allTimeArr, this.timeStartAndEnd)
        }
    },
    created () {

    }
}
</script>
<style lang='scss' scoped>
.rangeTime {
    display: flex;
    align-items: center;
    .el-date-editor--daterange.el-input,
    .el-date-editor--daterange.el-input__inner,
    .el-date-editor--timerange.el-input,
    .el-date-editor--timerange.el-input__inner {
        width: 190px;
    }
    .icon-tel {
        cursor: pointer;
    }
     ::v-deep .el-input__inner {
         background: none;
     }
     ::v-deep .el-range-input{
         background: none;
     }
     .el-date-editor{
         display: flex;
         align-items: center;
     }
}
.weekblock {
    width: 190px;
    position: relative;
    display: flex;
    align-items: center;
    font-size: 12px;
    ::v-deep .el-input__inner {
        text-align: center;
        border: none;
        height: 20px;
        line-height: 25px;
        width: 86px;
        padding:0;
        cursor: pointer;
    }
    ::v-deep .el-input__prefix{
        left: -6px;
    }
    .pickerContainer1{
        ::v-deep .el-input__inner {
           margin-left: 14px;
        }
    }
    ::v-deep .el-input__icon {
        height: 20px;
        line-height: 20px;
    }
    ::v-deep .el-input__suffix {
        display: none;
    }
    .weekDate {
        position: absolute;
        width: 110px;
        opacity: 0;
        z-index: 100;
    }
    .pickerContainer2 .weekDate {
        width: 90px;
    }
    .week-picker {
        display: flex;
        align-items: center;
        text-align: center;
        width: 100%;
    }
    .closeIcon {
        opacity: 1;
        cursor: pointer;
        color: #cccccc;
    }
    &:hover .closeIcon {
        opacity: 1;
    }
}
.month,
.days {
    ::v-deep {
        .el-range-editor.el-input__inner {
            padding: 0;
            cursor: pointer;
        }
        .el-input__inner {
            border: 0;
            height: 20px;
            line-height: 20px;
        }
        .el-range-input {
            cursor: pointer;
        }
        .el-date-editor .el-range__icon {
            line-height: 22px;
            margin: 0;
            width: 20px;
        }
        .el-date-editor .el-range-separator {
            line-height: 20px;
            padding: 0;
        }
        .el-date-editor .el-range-input {
            width: 86px;
            padding-top: -2px;
        }
        .el-date-editor .el-range__close-icon {
            line-height: 22px;
            width: 20px;
        }
    }
}
.month .el-date-editor--monthrange {
    width: 190px;
}
</style>