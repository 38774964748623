import axios from '@/config/httpConfig'
//获取里程碑信息
export function getProjectIdMilestone(data) {
  return axios.post('/ganttChart/getProjectIdMilestone', data)
}

//根据里程碑id获取此里程碑下所有子任务
export function getMilestoneIdSubTask(data) {
  return axios.post('/ganttChart/getMilestoneIdSubTask', data)
}

//根据子任务id获取前置后续详细信息
export function getIdSubTask(data) {
  return axios.post('/ganttChart/getIdSubTask', data)
}

//甘特图开始结束时间修改接口
export function updateInfo(data) {
  return axios.post('/ganttChart/updateInfo', data)
}

//子任务前置后续任务添加接口
export function addpronextproject(data) {
  return axios.post('/ganttChart/updateInfo', data)
}

//获取项目管理主页我的项目接口
export function getProjectInfo(data) {
  return axios.post('/project/getProjectInfo', data)
}
//项目柱状图id查询
export function getIdProjectInfo(data) {
  return axios.post('/project/getIdProjectInfo', data)
}
//获取项目管理主页团队成员
export function getProjectMemberList(data) {
  return axios.post('/member/getProjectMemberList', data)
}
//根据项目id获取成员信息
export function getProjectIdMember(data) {
  return axios.post('/member/getProjectIdMember', data)
}
//获取项目管理主页我未完成的任务
export function getNotCompletedTask(data) {
  return axios.post('/projectTask/getNotCompletedTask', data)
}
//获取项目管理主页我完成的任务
export function getCompletedTask(data) {
  return axios.post('/projectTask/getCompletedTask', data)
}
//获取项目管理主页资源分配
export function getMemberWorkHour(data) {
  return axios.post('/projectTask/getMemberWorkHour', data)
}

//获取项目管理主页逾期任务
export function getOverdueTask(data) {
  return axios.post('/projectTask/getOverdueTask', data)
}
//获取项目管理主页本周的工作
export function getThisWeekTask(data) {
  return axios.post('/projectTask/getThisWeekTask', data)
}
//获取项目管理主页问题状态
export function getProblemStatus(data) {
  return axios.post('/problem/getProblemStatus', data)
}

//获取项目管理主页甘特图
export function getPIdMilestone(data) {
  return axios.post('/ganttChart/getPIdMilestone', data)
}
//获取项目下的里程碑、任务、子任务
export function getMixedInformation(data) {
  return axios.post("/projectWorkList/getMixedInformation", data)
}
//获取项目下的问题
export function getProblemInformation(data) {
  return axios.post("/problem/getProblemInformation", data)
}
//关联列表点击新建-根据任务 问题 子任务 带出当前的任务或者问题
export function getProjectByTask(data) {
  return axios.post("/projectWorkList/getProjectByTask", data)
}
// 获取每个成员每天的资源利用情况
export function getMemberTotalHour(data) {
  return axios.post("/projectTask/getMemberTotalHour", data)
}
// 获取每个成员每周的资源利用情况
export function getMemberTotalHourWeek(data) {
  return axios.post("/projectTask/getMemberTotalHourWeek", data)
}
// 获取每个成员每月的资源利用情况
export function getMemberTotalHourMonth(data) {
  return axios.post("/projectTask/getMemberTotalHourMonth", data)
}
// 获取成员参与的任务信息
export function getMemberTask(data) {
  return axios.post("/projectTask/getMemberTask", data)
}
// 获取项目下的成员以及所在任务数量
export function getMemberAndTask(data) {
  return axios.post("/projectTask/getMemberAndTask", data)
}
//获取任务或子任务下的工时信息
export function getTaskHour(data) {
  return axios.post("/projectTask/getTaskHour", data)
}
// 里程碑排序查询页面
export function getMilestoneSort(data) {
  return axios.post("/projectGanttChart/getMilestoneSort", data)
}
// 保存任务或者里程碑的排序
export function saveMilestoneOrTaskSort(data) {
  return axios.post("/projectGanttChart/saveMilestoneOrTaskSort", data)
}

// 添加或更新对象记录
export function save(data) {
  return axios.post('/objectInfo/save', data)
}

// 获取视图列表记录
export function getViewListData(data) {
  return axios.post('/view/list/getViewListAjax', data)
}
